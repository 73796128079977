// Generated by Framer (7aa0232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {DtHaWU4kE: {hover: true}};

const cycleOrder = ["DtHaWU4kE"];

const variantClassNames = {DtHaWU4kE: "framer-v-gddm3c"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, M1tQYYIzu: image ?? props.M1tQYYIzu ?? {src: new URL("assets/512/0hIk4jOkKMJdcSf688GlSZVGF0.png", import.meta.url).href, srcSet: `${new URL("assets/512/0hIk4jOkKMJdcSf688GlSZVGF0.png", import.meta.url).href} 512w, ${new URL("assets/0hIk4jOkKMJdcSf688GlSZVGF0.png", import.meta.url).href} 750w`}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, M1tQYYIzu, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "DtHaWU4kE", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-dF5Wa", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={{hash: ":Xs5QbLMe1", webPageId: "augiA20Il"}} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx("framer-gddm3c", className)} framer-1iahrsv`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"DtHaWU4kE"} ref={ref} style={{...style}} {...addPropertyOverrides({"DtHaWU4kE-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", intrinsicHeight: 296, intrinsicWidth: 900, pixelHeight: 296, pixelWidth: 900, sizes: "min(103px, 100vw)", ...toResponsiveImage(M1tQYYIzu)}} className={"framer-5zlucw"} data-framer-name={"Logo_picto_ITC_Data"} layoutDependency={layoutDependency} layoutId={"Rd37eWFu0"}/><Image background={{alt: "", fit: "fit", intrinsicHeight: 296, intrinsicWidth: 900, pixelHeight: 296, pixelWidth: 900, sizes: "62px", ...toResponsiveImage(M1tQYYIzu)}} className={"framer-yi4rj4"} data-framer-name={"Logo_picto_ITC_Data"} layoutDependency={layoutDependency} layoutId={"hEuQTzN0q"} style={{opacity: 0.5}} {...addPropertyOverrides({"DtHaWU4kE-hover": {background: {alt: "", fit: "fit", intrinsicHeight: 296, intrinsicWidth: 900, pixelHeight: 296, pixelWidth: 900, sizes: "103px", ...toResponsiveImage(M1tQYYIzu)}}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-dF5Wa [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-dF5Wa .framer-1iahrsv { display: block; }", ".framer-dF5Wa .framer-gddm3c { height: 47px; overflow: hidden; position: relative; text-decoration: none; width: 103px; }", ".framer-dF5Wa .framer-5zlucw { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-dF5Wa .framer-yi4rj4 { bottom: -41px; flex: none; height: 31px; left: calc(50.00000000000002% - 62px / 2); overflow: visible; position: absolute; width: 62px; }", ".framer-dF5Wa .framer-v-gddm3c .framer-gddm3c { cursor: pointer; }", ".framer-dF5Wa.framer-v-gddm3c.hover .framer-5zlucw { bottom: 39px; height: 47px; top: unset; }", ".framer-dF5Wa.framer-v-gddm3c.hover .framer-yi4rj4 { bottom: -2px; height: 49px; left: calc(49.51456310679614% - 103px / 2); width: 103px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 47
 * @framerIntrinsicWidth 103
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wnen9pkyS":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"M1tQYYIzu":"image"}
 */
const FramerpwH45rrCz: React.ComponentType<Props> = withCSS(Component, css, "framer-dF5Wa") as typeof Component;
export default FramerpwH45rrCz;

FramerpwH45rrCz.displayName = "logo data";

FramerpwH45rrCz.defaultProps = {height: 47, width: 103};

addPropertyControls(FramerpwH45rrCz, {M1tQYYIzu: {__defaultAssetReference: "data:framer/asset-reference,0hIk4jOkKMJdcSf688GlSZVGF0.png?originalFilename=Ecriture+neo+impex.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerpwH45rrCz, [])